.sk-wave {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: space-between;
}

.sk-wave-rect {
  background-color: white;
  height: 100%;
  width: 15%;
  animation: sk-wave 1.2s infinite ease-in-out;
}

.sk-wave-rect:nth-child(1) { animation-delay: -1.2s; }
.sk-wave-rect:nth-child(2) { animation-delay: -1.1s; }
.sk-wave-rect:nth-child(3) { animation-delay: -1.0s; }
.sk-wave-rect:nth-child(4) { animation-delay: -0.9s; }
.sk-wave-rect:nth-child(5) { animation-delay: -0.8s; }

@keyframes sk-wave {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  } 20% {
    transform: scaleY(1);
  } 
}
