#blazor-error-ui {
    background: rgb(255, 82, 79);
    display: none;
    position: fixed;
    width: 100%;
    padding-top: 0.26rem;
    height: 2rem;
    text-align: center;
    color: white;
    font-size: 0.875rem;
    z-index: 99999;
}

#blazor-error-ui .dismiss {
    cursor: pointer;
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
}

#blazor-error-ui .reload {
  text-decoration: underline;
}
